<template>
  <v-dialog
    v-model="dialog"
    persistent
    transition="dialog-bottom-transition"
    max-width="650"
  >
    <!--begin::Modal dialog-->
    <div class="bg-white poppins">
      <!--begin::Modal header-->
      <div class="modal-header align-center">
        <h3 class="mb-0 font-weight-bolder">Add city</h3>
        <!--begin::Close-->
        <div
          class="btn btn-sm btn-icon btn-active-light-info"
          @click="toggleModal"
        >
          <span class="svg-icon">
            <v-icon size="22">mdi-close</v-icon>
          </span>
        </div>
        <!--end::Close-->
      </div>
      <!--end::Modal header-->
      <!--begin::Modal body-->
      <div
        class="modal-body d-flex flex-column h-550px scroll-y mx-5 mx-xl-15 py-7"
      >
        <div class="h-75px">
          <v-text-field
            v-model="formData.title"
            dense
            label="Title"
            clearable
            outlined
          ></v-text-field>
        </div>
        <div class="h-75px">
          <v-text-field
            v-model="formData.iso_3"
            dense
            label="Iso 3"
            clearable
            outlined
          ></v-text-field>
        </div>
        <div class="h-75px">
          <v-text-field
            v-model="formData.iso_2"
            dense
            label="Iso 2"
            clearable
            outlined
          ></v-text-field>
        </div>
        <!--begin::Actions-->
        <div class="mb-4 d-flex flex-grow-1 align-end justify-center">
          <button
            type="reset"
            class="btn btn-light mr-3 px-5 py-3 ls1"
            @click="resetCreateForm"
          >
            Clear
          </button>
          <button
            type="submit"
            class="btn btn-info px-5 py-3 ls1"
            @click="submitCreateForm"
          >
            Submit
          </button>
        </div>
        <!--end::Actions-->
      </div>
      <!--end::Modal body-->
    </div>
    <!--end::Modal dialog-->
  </v-dialog>
</template>

<script>
import ApiService from "@/core/services/api.service";
import { SET_PAGE_LOADING } from "@/core/services/store/config.module";
import swalAdded from "@/core/services/AlertServices/SwalAdded";
import { GET_CITIES } from "@/core/services/store/shipping_countries.module";

export default {
  name: "AddCity",
  data: () => ({
    dialog: false,
    // selectedStateId: null,
    formData: {
      title: "",
      iso_3: "",
      iso_2: "",
    },
  }),
  computed: {
    selectedStateId: function () {
      return this.$store.getters.getSHIPPINGCOUNTRIESSelectedStateId;
    },
  },
  methods: {
    toggleModal() {
      if (this.dialog) {
        this.resetCreateForm();
      }
      this.dialog = !this.dialog;
    },
    submitCreateForm() {
      let sendData = {
        type: "city",
        state_id: this.selectedStateId,
        ...this.formData,
      };
      this.$store.commit(SET_PAGE_LOADING, true);
      if (this.$store.getters.getSHIPPINGCOUNTRIESOrganizationId) {
        sendData["organization_id"] =
          this.$store.getters.getSHIPPINGCOUNTRIESOrganizationId;
      }
      ApiService.post("/shipping/countries/store", sendData)
        .then(() => {
          swalAdded();
          this.toggleModal();
          let payload = { state_id: this.selectedStateId, type: "city" };
          this.$store.dispatch(GET_CITIES, payload).finally(() => {
            this.$store.commit(SET_PAGE_LOADING, false);
          });
        })
        .finally(() => {
          this.$store.commit(SET_PAGE_LOADING, false);
        });
    },
    resetCreateForm() {
      this.formData = {
        title: null,
        iso_3: null,
        iso_2: null,
      };
    },
  },
};
</script>
