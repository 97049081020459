import { SET_PAGE_LOADING } from "@/core/services/store/config.module";
import ApiService from "@/core/services/api.service";
import swalEdited from "@/core/services/AlertServices/SwalEdited";
import {
  GET_AREAS,
  GET_CITIES,
  GET_COUNTRIES,
  GET_STATES,
} from "@/core/services/store/shipping_countries.module";

export default {
  methods: {
    loadDataFromServer() {
      let sendData = {
        id: this.id,
        type: this.editType,
      };
      this.$store.commit(SET_PAGE_LOADING, true);
      ApiService.post("/shipping/countries/edit", sendData)
        .then(({ data }) => {
          this.formData = data.value;
        })
        .finally(() => {
          this.$store.commit(SET_PAGE_LOADING, false);
        });
    },
    toggleModal() {
      if (this.dialog) this.resetCreateForm();
      else this.loadDataFromServer();
      this.dialog = !this.dialog;
    },
    sentToApi(sendData) {
      this.$store.commit(SET_PAGE_LOADING, true);
      ApiService.post("/shipping/countries/update", sendData)
        .then(() => {
          swalEdited();
          this.toggleModal();
          if (this.editType === "country")
            this.$store.dispatch(GET_COUNTRIES).finally(() => {
              this.$store.commit(SET_PAGE_LOADING, false);
            });
          else if (this.editType === "state") {
            let payload = {
              country_id:
                this.$store.getters.getSHIPPINGCOUNTRIESSelectedCountryId,
              type: "state",
            };
            this.$store.dispatch(GET_STATES, payload).finally(() => {
              this.$store.commit(SET_PAGE_LOADING, false);
            });
          } else if (this.editType === "city") {
            let payload = {
              state_id: this.$store.getters.getSHIPPINGCOUNTRIESSelectedStateId,
              type: "city",
            };
            this.$store.dispatch(GET_CITIES, payload).finally(() => {
              this.$store.commit(SET_PAGE_LOADING, false);
            });
          } else {
            let payload = {
              city_id: this.$store.getters.getSHIPPINGCOUNTRIESSelectedCityId,
              type: "area",
            };
            this.$store.dispatch(GET_AREAS, payload).finally(() => {
              this.$store.commit(SET_PAGE_LOADING, false);
            });
          }
        })
        .finally(() => {
          this.$store.commit(SET_PAGE_LOADING, false);
        });
    },
  },
};
