<template>
  <div class="p-4 pb-8 bg-white rounded-lg">
    <div class="mb-7 mx-4 mt-6 d-flex align-center justify-space-between">
      <datatable-detail
        class="col-9 col-md-9 col-sm-9"
        :table_properties="tableProperties"
      ></datatable-detail>
      <div v-if="$store.getters?.getSHIPPINGCOUNTRIESOrganizations">
        <v-select
          v-model="organization_id"
          label="Organization"
          item-text="text"
          item-value="index"
          :items="$store.getters.getSHIPPINGCOUNTRIESOrganizations"
          clearable
          outlined
          dense
        ></v-select>
      </div>
    </div>
    <div
      class="custom-container-height w-100 mx-0 pb-3 px-0 px-sm-2 px-md-8 d-flex flex-wrap poppins"
    >
      <NestedDataTable
        tableName="countries"
        editType="country"
        :handleAddFunction="() => handleAddFunction('country')"
        :handleGetFunction="getStates"
      />
      <NestedDataTable
        tableName="states"
        editType="state"
        :handleAddFunction="() => handleAddFunction('state')"
        :handleGetFunction="getCities"
      />
      <NestedDataTable
        tableName="cities"
        editType="city"
        :handleAddFunction="() => handleAddFunction('city')"
        :handleGetFunction="getAreas"
      />
      <NestedDataTable
        tableName="areas"
        editType="area"
        :handleAddFunction="() => handleAddFunction('area')"
      />
    </div>
    <AddArea ref="addAreaDialog" />
    <AddCity ref="addCityDialog" />
    <AddState ref="addStateDialog" />
    <AddCountry ref="addCountryDialog" />
  </div>
</template>

<script>
import DatatableDetail from "@/own/components/datatable/DatatableDetail";
import countryNestedDataMixin from "@/own/mixins/countryNestedDataMixin";
import AddArea from "@/own/components/shipping/countries/Add/AddArea";
import AddCity from "@/own/components/shipping/countries/Add/AddCity";
import AddState from "@/own/components/shipping/countries/Add/AddState";
import AddCountry from "@/own/components/shipping/countries/Add/AddCountry";
import NestedDataTable from "@/own/components/shipping/countries/NestedDataTable";

export default {
  name: "NestedData",
  components: {
    NestedDataTable,
    AddCountry,
    AddState,
    AddCity,
    AddArea,
    DatatableDetail,
  },
  mixins: [countryNestedDataMixin],
};
</script>

<style scoped>
.custom-container-height {
  overflow-y: scroll;
  position: relative;
  height: calc(100vh - 327px) !important;
}

@media (max-width: 1199.98px) {
  .custom-container-height {
    height: calc(100vh - 300px) !important;
  }
}

@media (max-width: 991.98px) {
  .custom-container-height {
    height: calc(100vh - 285px) !important;
  }
}

@media (max-width: 768px) {
  .custom-container-height {
    height: calc(100vh - 275px) !important;
  }
}
</style>
